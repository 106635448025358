<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12"><h1>Account Setting</h1></div>
    </div>
    <div class="row">
      <div class="col-md-3 d-none d-md-block" id="menuWrapper">
        <AccountSettingMenu activeMenu="payment-method" />
      </div>
      <div class="col-md-9">
        <SettingTitle title="Payment Method" />
        <div>
          <button class="btn btn-primary">
            <i class="fa fa-plus"></i>
            Add credit or debit card
          </button>

          <button class="btn btn-primary ms-3">
            <i class="fa fa-plus"></i>
            Add paypal account
          </button>
        </div>

        <div class="row mt-3">
          <div class="col-md-4">
            <div class="credit-card">
              <div class="cc-bank-name" title="Trepr"><img src="/img/payment/visa.png" /></div>
              <div class="cc-chip">
                <div class="cc-chip-side cc-chip-side-left"></div>
                <div class="cc-chip-side cc-chip-side-right"></div>
                <div class="cc-chip-vertical cc-chip-vertical-top"></div>
                <div class="cc-chip-vertical cc-chip-vertical-bottom"></div>
              </div>
              <div class="cc-data">
                <div class="cc-acc-number" title="4123 4567 8910 1112">xxxx xxxx xxxx 1111</div>
                <div class="cc-exp-date-wrapper">
                  <div class="cc-exp-date">
                    <div class="upper-labels">VALID THRU</div>
                    <div class="cc-date" title="11 / 2023">11/2023</div>
                  </div>
                </div>
                <div class="cc-name" title="Savita Ingaleshwar ">Savita Ingaleshwar</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingMenu from './Menu';
import SettingTitle from './SettingTitle';
export default {
  name: 'AccountSetting',
  components: { AccountSettingMenu, SettingTitle },
};
</script>

<style scoped>
.credit-card {
  /*margin: auto;*/
  width: 325px;
  height: 200px;
  color: #fff;
  font: 22px/1 'Iceland', monospace;
  background: #0d4280;
  border: 1px solid #1e1584;
  -webkit-border-radius: 10px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 10px;
  -moz-background-clip: padding;
  border-radius: 10px;
  background-clip: padding-box;
  overflow: hidden;
}
.credit-card .cc-delete {
  position: absolute;
  right: 40px;
  top: 25px;
  padding: 6px 10px;
  border-radius: 100%;
  background: #d21609;
  z-index: 11;
  color: white;
  font-size: 16px;
  font-weight: 900;
}
.credit-card .cc-bank-name {
  float: right;
  margin-top: 15px;
  margin-right: 30px;
  font: 800 28px 'Open Sans', Arial, sans-serif;
}
.credit-card .cc-bank-name img {
  width: 48px;
  z-index: 10;
  position: relative;
}
.credit-card .cc-chip {
  position: relative;
  z-index: 1000;
  width: 50px;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-left: 30px;
  background: #fffcb1;
  background: -moz-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0%, #fffcb1),
    color-stop(100%, #b4a365)
  );
  background: -webkit-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  background: -o-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  background: -ms-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  background: linear-gradient(135deg, #fffcb1 0%, #b4a365 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fffcb1", endColorstr="#b4a365", GradientType=1);
  border: 1px solid #322d28;
  -webkit-border-radius: 10px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 10px;
  -moz-background-clip: padding;
  border-radius: 10px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 2px #322d28, 0 0 5px 0 0 5px rgba(144, 133, 87, 0.25) inset;
  -moz-box-shadow: 0 1px 2px #322d28, 0 0 5px 0 0 5px rgba(144, 133, 87, 0.25) inset;
  box-shadow: 0 1px 2px #322d28, 0 0 5px 0 0 5px rgba(144, 133, 87, 0.25) inset;
  overflow: hidden;
}
.credit-card .cc-chip .cc-chip-side {
  position: absolute;
  top: 8px;
  width: 12px;
  height: 24px;
  border: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1) inset;
}
.credit-card .cc-chip .cc-chip-side:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: inline-block;
  width: 100%;
  height: 0px;
  margin: auto;
  border-top: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1);
}
.credit-card .cc-chip .cc-chip-side.cc-chip-side-left {
  left: 0;
  border-left: none;
  -webkit-border-radius: 0 2px 2px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 2px 2px 0;
  -moz-background-clip: padding;
  border-radius: 0 2px 2px 0;
  background-clip: padding-box;
}
.credit-card .cc-chip .cc-chip-side.cc-chip-side-right {
  right: 0;
  border-right: none;
  -webkit-border-radius: 2px 0 0 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px 0 0 2px;
  -moz-background-clip: padding;
  border-radius: 2px 0 0 2px;
  background-clip: padding-box;
}
.credit-card .cc-chip .cc-chip-vertical {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 8.66666667px;
  height: 12px;
  border: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1) inset;
}
.credit-card .cc-chip .cc-chip-vertical:after {
  content: '';
  position: absolute;
  left: -8.66666667px;
  display: inline-block;
  width: 26px;
  height: 0px;
  margin: 0;
  border-top: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset, 0 0 5px rgba(144, 133, 87, 0.25),
    0 0 4px rgba(0, 0, 0, 0.1);
}
.credit-card .cc-chip .cc-chip-vertical.cc-chip-vertical-top {
  top: 0;
  border-top: none;
}
.credit-card .cc-chip .cc-chip-vertical.cc-chip-vertical-top:after {
  top: 12px;
  width: 17.33333333px;
}
.credit-card .cc-chip .cc-chip-vertical.cc-chip-vertical-bottom {
  bottom: 0;
  border-bottom: none;
}
.credit-card .cc-chip .cc-chip-vertical.cc-chip-vertical-bottom:after {
  bottom: 12px;
}
.credit-card .cc-data {
  position: relative;
  z-index: 100;
  margin-left: 30px;
  text-transform: uppercase;
}
.credit-card .cc-data .cc-acc-number {
  position: relative;
  z-index: 50;
  margin: 0;
  letter-spacing: 1px;
  font-size: 24px;
  text-shadow: 1px 1px 1px #000;
}
.credit-card .cc-data .cc-exp-date-wrapper {
  margin-top: 5px;
  margin-left: 64px;
  line-height: 1;
}
.credit-card .cc-data .cc-exp-date-wrapper:before {
  content: ' ';
  display: table;
}
.credit-card .cc-data .cc-exp-date-wrapper:after {
  content: ' ';
  display: table;
  clear: both;
}
.credit-card .cc-data .cc-exp-date-wrapper .cc-exp-date {
  display: inline-block;
  float: right;
  margin-top: 5px;
  text-align: center;
  width: 100px;
}
.credit-card .cc-data .cc-exp-date-wrapper .cc-exp-date .upper-labels {
  font-size: 10px;
}
.credit-card .cc-data .cc-exp-date-wrapper .cc-date {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  z-index: 20;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px #000;
}
.credit-card .cc-data .cc-name {
  margin-top: 0px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  z-index: 20;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px #000;
}
.credit-card .lines-down:before {
  content: '';
  position: absolute;
  top: 80px;
  left: -200px;
  z-index: 10;
  width: 550px;
  height: 400px;
  border-top: 2px solid #3f51b5;
  -webkit-border-radius: 40% 60% 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 40% 60% 0 0;
  -moz-background-clip: padding;
  border-radius: 40% 60% 0 0;
  background-clip: padding-box;
  box-shadow: 1px 1px 100px #4035b2;
  background: #2d21a6;
  background: -moz-radial-gradient(center, ellipse cover, rgba(45, 33, 166, 0) 100%, #2d21a6 100%);
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(45, 33, 166, 0)),
    color-stop(100%, #2d21a6)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #2d21a6 100%
  );
  background: -o-radial-gradient(center, ellipse cover, rgba(45, 33, 166, 0) 44%, #2d21a6 100%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(45, 33, 166, 0) 44%, #2d21a6 100%);
  background: radial-gradient(ellipse at center, rgba(45, 33, 166, 0) 44%, #2d21a6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(45, 33, 166, 0)", endColorstr="#2d21a6", GradientType=1);
}
.credit-card .lines-down:after {
  content: '';
  position: absolute;
  top: 20px;
  left: -100px;
  z-index: 10;
  width: 350px;
  height: 400px;
  border-top: 2px solid #392db2;
  -webkit-border-radius: 20% 80% 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 20% 80% 0 0;
  -moz-background-clip: padding;
  border-radius: 20% 80% 0 0;
  background-clip: padding-box;
  box-shadow: inset -1px -1px 44px #4035b2;
  background: #2d21a6;
  background: -moz-radial-gradient(center, ellipse cover, rgba(45, 33, 166, 0) 100%, #2d21a6 100%);
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(45, 33, 166, 0)),
    color-stop(100%, #2d21a6)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #2d21a6 100%
  );
  background: -o-radial-gradient(center, ellipse cover, rgba(45, 33, 166, 0) 44%, #2d21a6 100%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(45, 33, 166, 0) 44%, #2d21a6 100%);
  background: radial-gradient(ellipse at center, rgba(45, 33, 166, 0) 44%, #2d21a6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(45, 33, 166, 0)", endColorstr="#2d21a6", GradientType=1);
}
.credit-card .lines-up:before {
  content: '';
  position: absolute;
  top: -110px;
  left: -70px;
  z-index: 2;
  width: 480px;
  height: 300px;
  border-bottom: 2px solid #392db2;
  -webkit-border-radius: 0 0 60% 90%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 60% 90%;
  -moz-background-clip: padding;
  border-radius: 0 0 60% 90%;
  background-clip: padding-box;
  box-shadow: inset 1px 1px 44px #4035b2;
  background: #4031b2;
  background: -moz-radial-gradient(center, ellipse cover, rgba(64, 49, 178, 0) 100%, #23189a 100%);
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(64, 49, 178, 0)),
    color-stop(100%, #23189a)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 49, 178, 0) 100%,
    #23189a 100%
  );
  background: -o-radial-gradient(center, ellipse cover, rgba(64, 49, 178, 0) 44%, #23189a 100%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(64, 49, 178, 0) 44%, #23189a 100%);
  background: radial-gradient(ellipse at center, rgba(64, 49, 178, 0) 44%, #23189a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(64, 49, 178, 0)", endColorstr="#23189a", GradientType=1);
}
.credit-card .lines-up:after {
  content: '';
  position: absolute;
  top: -180px;
  left: -200px;
  z-index: 1;
  width: 530px;
  height: 420px;
  border-bottom: 2px solid #4035b2;
  -webkit-border-radius: 0 40% 50% 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 40% 50% 50%;
  -moz-background-clip: padding;
  border-radius: 0 40% 50% 50%;
  background-clip: padding-box;
  box-shadow: inset 1px 1px 44px #4035b2;
  background: #2d21a6;
  background: -moz-radial-gradient(center, ellipse cover, rgba(45, 33, 166, 0) 100%, #2d21a6 100%);
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(45, 33, 166, 0)),
    color-stop(100%, #2d21a6)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #2d21a6 100%
  );
  background: -o-radial-gradient(center, ellipse cover, rgba(45, 33, 166, 0) 44%, #2d21a6 100%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(45, 33, 166, 0) 44%, #2d21a6 100%);
  background: radial-gradient(ellipse at center, rgba(45, 33, 166, 0) 44%, #2d21a6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(45, 33, 166, 0)", endColorstr="#2d21a6", GradientType=1);
}
</style>
